@font-face {
	font-family: "Raleway";
	src: url("./fonts/Raleway.ttf");
}

* {
	font-family: "Raleway";
}

#vertical-custom-calendar {
	height: 100%;
}

#loader {
	position: absolute;
	height: 100vh;
	width: 100vw;
	z-index: 9999;
	background: white;
}

.centered {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.buttonGroupCustom1 {
	height: 50%;
}

.buttonGroupCustom1 .btn {
	border: none;
	color: #2678bc;
	background-color: white;
}

.buttonGroupCustom1 .btn.selected {
	border: none;
	color: #2678bc;
	background-color: #efefef;
}

.buttonGroupCustom1 .btn:hover {
	background-color: #efefef;
}

.buttonGroupCustom1 {
	border: 1px black #2678bc;
}

.basicRow {
	background-color: #eeeeee;
}

.main-color {
	color: #2678bc !important;
}

.main-button {
	color: #2678bc !important;
	font-weight: 700 !important;
	border-color: #6d6d6d2c !important;
	background-color: white !important;
}

.main-button:hover {
	color: #155a92 !important;
	font-weight: 700 !important;
	border-color: #e6e6e6a6 !important;
	background-color: rgb(243, 243, 243) !important;
}

.main-bg {
	background-color: #2778bc !important;
}

.secondary-bg,
.booked-space {
	background-color: #55a5e6;
}

.grey-bg {
	background-color: #eeeeee;
}

.grey-text {
	color: #eeeeee;
}

.space {
	height: 100%;
	border: 1px solid #dbdbdb;
	font-size: 0.8rem;
}

.open-space {
	background-color: #c4ebc4;
	opacity: 0.9;
}

.open-space:hover {
	background-color: #c4ebc4;
	opacity: 1;
}

.isDragOver {
	background-color: #155a92;
	cursor: progress;
}

.widthCustom75To100 {
	width: 75%;
}

.widthCustomAutoTo50 {
	width: auto;
}

.widthCustomAutoTo100 {
	width: auto;
}

.lineHeightCustom {
	line-height: 8px;
}

.text-border {
	color: white;
	/* Text color */
	text-shadow:
		-1px -1px 0 #155a92,
		1px -1px 0 #155a92,
		-1px 1px 0 #155a92,
		1px 1px 0 #155a92;
	/* Shadow effect to create the border */
}

@media only screen and (max-width: 768px) {
	#vertical-custom-calendar {
		height: 100vh;
	}

	.widthCustomAutoTo50 {
		width: 50%;
	}

	.widthCustom75To100 {
		width: 100%;
	}

	.widthCustomAutoTo100 {
		width: 100%;
	}

	.lineHeightCustom {
		line-height: 22px;
	}
}

.flash {
	animation: flash 0.5s linear infinite;
}

@keyframes flash {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}