.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none !important;
}

.react-calendar__month-view__weekdays {
	text-align: center;
}

.react-calendar__month-view__weekdays abbr {
	text-decoration: none!important;
	cursor: default!important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__label {
	background-color: transparent !important;
	border: none !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
	font-size: 35px;
}

.react-calendar__navigation {
	display: flex;
	justify-content: space-between;
	font-size: 25px;
}

.react-calendar__navigation__label {
	pointer-events: none;
}

.react-calendar__tile {
	padding: 4%;
	border: solid 5px white !important;
	color: #2678bc;
	background-color: #efefef;
	display: flex;
	font-weight: 700;
	justify-content: flex-end;
	align-items: top;
	border: #2678bc;
}

.react-calendar__tile.highlight {
	-webkit-box-shadow: inset 0px 0px 0px 4px #2678bc;
	-moz-box-shadow: inset 0px 0px 0px 4px #2678bc;
	box-shadow: inset 0px 0px 0px 4px #2678bc;
}

.react-calendar__tile.highlight-available {
	background-color: #c4ebc4;
}

.react-calendar__tile.disabled-click {
	pointer-events: none;
}

.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
	background-color: #efefef;
	color: #efefef;
	opacity: 0.2;
}

.small-calendar-tiles button:not(:disabled),
.small-calendar-tiles [type="button"]:not(:disabled),
.small-calendar-tiles [type="reset"]:not(:disabled),
.small-calendar-tiles [type="submit"]:not(:disabled) {
	height: 8.5vh;
}

.before-date {
	opacity: 0.5;
}
